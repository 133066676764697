import { routingManifest } from '../components/manifest/manifest';
import SEO from '../components/seo';
import { reconfigureEntries } from '@utils/utils';
import axios from 'axios';
import dynamic from 'next/dynamic';

const DynamicWrapper = dynamic(() => import('../layout/wrapper'), {
  ssr: false,
});

export default function Home() {
  const RootComponent = routingManifest.root;

  return (
    <>
      <DynamicWrapper>
        <SEO />
        <RootComponent />
      </DynamicWrapper>
    </>
  );
}
